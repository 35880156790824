.view {
  width: 100%;
  display: flex;

  .viewContainer {
    flex: 6;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .wrapper {
      width: 85%;
      padding: 10px;
      margin: 0 auto 33px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff; /* Set background color for contrast */
      border-radius: 4px; /* Add rounded corners */
      border: 1px solid rgb(230, 227, 227);
    }

    .header {
      width: 97%;
      border: none;
      text-align: center;
      color: #FF6000;
      font-weight: bold; /* Increase header weight for emphasis */
      padding: 15px 15px 10px;
      margin-bottom: 15px; /* Add space between header and data section */
      background-color: #ffffff; /* Light background for data section */
      border-bottom: 2px solid rgb(230, 227, 227);
    }

    .dataSection {
      width: 97%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 10px 40px;
      border: none;
      background-color: white; /* Light background for data section */
      margin-bottom: 15px; /* Add space between header and data section */

      .dataItem {
        width: calc(45%);
        display: flex; /* Change display to flex for horizontal layout */
        flex-direction: column;
        margin: 10px;
        padding: 10px;
        transition: transform 0.2s ease-in-out; /* Add hover animation */
        border-bottom: 2px solid rgb(230, 227, 227);


        &:hover {
          transform: translateY(-2px); /* Slight lift on hover */
        }

        label {
          font-size: 13px;
          font-weight: bold; /* Emphasize labels */
          margin-bottom: 5px; /* Add some space between label and span */
          color: #FF6000;
        }

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          max-width: 100%;
        }
      }

      // Media query for smaller screens
      @media (max-width: 768px) {
        .dataItem {
          width: calc(100% - 20px);
        }
      }
    }
  }
}
