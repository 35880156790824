.widget {
  display: flex;
  flex: 1;
  padding: 10px;
  justify-content: space-between;
  -webkit-box-shadow: -2px 0px 18px 0px rgba(208, 208, 208, 0.5);
  box-shadow: -2px 0px 18px 0px rgba(208, 208, 208, 0.5);
  border-radius: 5px;
  height: 125px;

  .left, .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 14px;
      color: #333;
    }

    .counter {
      padding-left: 6px;
      font-size: 28px;
      font-weight: 300;
    }

    .link {
      width: max-content;
      font-size: 12px;
      border-bottom: 1px solid rgb(230, 227, 227);
      cursor: pointer;
    }

    .addIcon {
      background-color: rgba(82, 157, 2, 0.3);
      padding: 2.5px;
      border-radius: 5px;
      color: #529d02;
      font-size: 25px;
      align-self: flex-end;
      transition: all 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        scale: 1.1;
      }
    }

    .icon {
      color: #ff6000;
      font-size: 25px;
      border-radius: 5px;
      align-self: flex-end;
      transition: all 0.3s ease-in-out;

      &:hover {
        scale: 1.1;
      }
    }
  }
}
