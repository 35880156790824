.datatable {
  height: 700px;
  padding: 20px;

  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: #212529;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .links {
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
    }

    .linkCreate {
      border: 1px dotted #529d02;
      color: #529d02;
      padding: 5px;
      border-radius: 5px;

      &:hover {
        border: 1px solid #529d02;
      }
    }

    .linkDeleteMany {
      border: 1px dotted rgba(220, 20, 60, 0.6);
      color: crimson;
      padding: 5px;
      border-radius: 5px;
      margin-right: 10px;
    }
  }

  .MuiTablePagination-displayedRows {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .MuiTablePagination-selectLabel {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: #529d02;
    }

    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }

    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
        border: 1px solid rgba(0, 0, 139, 0.596);
      }
    }

    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
        border: 1px solid rgba(220, 20, 60, 0.6);
      }
    }

    .downloadButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: black;
      border: 1px dotted black;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
        border: 1px solid black;
      }
    }

    .editButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: #dc8c14;
      border: 1px dotted rgb(220, 140, 20);
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
        border: 1px solid rgb(220, 140, 20);
      }
    }

    .raceViewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: #0378c0;
      border: 1px dotted rgb(3, 120, 192);
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
        border: 1px solid rgb(3, 120, 192);
      }
    }

    .registerButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: #529d02;
      border: 1px dotted rgb(82, 157, 2);
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
        border: 1px solid rgb(82, 157, 2);
      }
    }
  }
}

.alert {
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
