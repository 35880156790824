.update {
  width: 100%;
  display: flex;

  .updateFormContainer {
    flex: 6;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .wrapper {
      width: 65%;
      padding: 10px;
      margin: 0 auto 33px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff; /* Set background color for contrast */
      border-radius: 4px; /* Add rounded corners */
      border: 1px solid rgb(230, 227, 227);
    }

    .header {
      width: 97%;
      border: none;
      text-align: center;
      color: #FF6000;
      font-weight: bold; /* Increase header weight for emphasis */
      padding: 15px 15px 10px;
      margin-bottom: 15px; /* Add space between header and data section */
      background-color: #ffffff; /* Light background for data section */
      border-bottom: 2px solid rgb(230, 227, 227);
    }

    .dataSection {
      width: 97%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 10px 40px;
      border: none;
      background-color: white; /* Light background for data section */
      margin-bottom: 15px; /* Add space between header and data section */
      border-bottom: 2px solid rgb(230, 227, 227);

      h2 {
        color: #FF6000;
        font-size: 15px;
      }

      .categoryRow {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
      }

      .labelRow {
        margin-bottom: 5px;
      }

      .inputRow {
        display: flex;
        align-items: center;
        gap: 10px;

      }

      .inputRow p {
        margin: 0;
        display: flex;
        align-items: center; /* Align text vertically center with input */
      }

      .inputRow input {
        padding: 5px;
        border: 0.5px solid rgb(230, 227, 227);
        border-radius: 4px;
        width: 59%; /* Adjust the width as needed */
        background-color: #f5f5f5; /* Light background for data section */
      }

      .checkboxes {
        flex: 2;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
      }

      .right {
        flex: 2;
        width: 100%;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          .formCategoryInput {
            width: calc(100%);

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: 0.5px solid rgb(230, 227, 227);
              border-radius: 4px;
              background-color: #f5f5f5; /* Light background for data section */

            }
          }

          .formInput {
            width: calc(45%);

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: 0.5px solid rgb(180, 178, 178);
              border-radius: 4px;
              background-color: #f5f5f5; /* Light background for data section */
            }
          }

          .formText {
            width: calc(50% - 30px);
            padding-bottom: 20px;

            label {
              display: flex;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            span {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              padding: 5px;
              border: 0.5px solid rgb(230, 227, 227);
              border-radius: 4px;
              cursor: pointer
            }
          }

          .categoryFormInput {
            width: calc(50% - 15px);
          }

          button {
            width: 100px;
            background-color: white;
            border: 1px solid rgb(230, 227, 227);
            color: #FF6000;
            font-weight: bold;
            cursor: pointer;
            margin: 0 auto;
            display: block;
            transition: all 0.3s ease-in-out;

            &:hover {
              border: 1px solid rgb(255, 96, 0);
              transform: scale(1.1);
            }
          }

          .formIcon {
            width: calc(1%);
            padding-top: 25px;
            cursor: pointer;
          }
        }

        .addIcon {
          font-weight: bold;
          cursor: pointer;
          margin: 0 auto;
          display: block;
          color: green;
          transition: transform 0.1s ease-in-out;
          margin-top: 15px;

          &:hover {
            transform: scale(1.4);
          }
        }
      }
    }
  }

  /* Adjust alert width */
  .MuiAlert-root {
    width: 80%;
    margin: 10px auto;
  }
}
