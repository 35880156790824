.scaleAnimation {
  animation: scaleAnimation 1.5s ease forwards;
  opacity: 0;
  transform: scale(0.5); /* Start with smaller size */
}

@keyframes scaleAnimation {
  from {
    opacity: 0;
    transform: scale(0.5); /* Start with smaller size */
  }
  to {
    opacity: 1;
    transform: scale(1); /* Scale to original size */
  }
}
