.featured {
  flex: 4;
  -webkit-box-shadow: -2px 0px 18px 0px rgba(208, 208, 208, 0.5);
  box-shadow: -2px 0px 18px 0px rgba(208, 208, 208, 0.5);
  padding: 10px;
  border-radius: 10px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;

    .title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .bottom {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .featuredChart {
      width: 100px;
      height: 100px;
    }

    .title {
      font-weight: 500;
      color: #333;
    }

    .amount {
      font-size: 30px;
    }

    .desc {
      font-weight: 300;
      font-size: 12px;
      color: #333;
      text-align: center;
    }
  }
}
