.fadeSlideIn {
  animation: fadeSlideInAnimation 1.5s ease forwards;
  opacity: 0;
  transform: translateY(50px); /* Start from below */
}

@keyframes fadeSlideInAnimation {
  from {
    opacity: 0;
    transform: translateY(50px); /* Start from below */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Slide to original position */
  }
}
