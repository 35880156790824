.sidebar {
  flex: 1;
  min-height: 100vh;
  min-width: 135px;
  background-color: #fff; /* White background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s ease-in-out; /* Add transition for slide animation */

  &.sidebar-hidden { /* Add class for hidden state */
    transform: translateX(-100%); /* Slide sidebar off-screen */
  }

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #ff6000;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid #e0e0e0; /* Light border color */
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 12px; /* Increased title size */
        font-weight: bold;
        color: #ff6000;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out; /* Add hover transition */

        &:hover {
          background-color: #f5f5f5; /* Light hover background */
        }

        .icon {
          font-size: 18px;
          color: #ff6000;
          margin-right: 10px; /* Add space between icon and text */
        }

        span {
          font-size: 14px; /* Increased text size */
          font-weight: 600;
          color: #212529; /* Darker text color */
        }
      }
    }
  }

  .bottom {
    align-items: center;
    margin-left: 10px;
    margin-top: 50px;

    li {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out; /* Add hover transition */

      &:hover {
        background-color: #f5f5f5; /* Light hover background */
      }

      .icon {
        font-size: 18px;
        color: #ff6000;
        margin-right: 10px; /* Add space between icon and text */
      }

      span {
        font-size: 14px; /* Increased text size */
        font-weight: 600;
        color: #333; /* Darker text color */
      }
    }
  }

  .colorOption {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #7451f8;
    cursor: pointer;
    margin: 5px;

    &:nth-child(1) {
      background-color: whitesmoke;
    }

    &:nth-child(2) {
      background-color: #333;
    }

    &:nth-child(3) {
      background-color: darkblue;
    }


  }
}
