.organizator {
  display: flex;

  .homeContainer {
    flex: 6;

    .widgets, .charts {
      display: flex;
      padding: 20px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px
    }
  }
}

@media only screen and (max-width: 768px) {
  .homeContainer {
    flex: none;
    width: 100%;
  }

  .widgets, .charts {
    flex-direction: column;
  }
}

